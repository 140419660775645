import documentFooter from "@/assets/footer.json";
import { LogomarkWithNameBlue } from "@/assets/vectors/logo";
import { SocialIcon } from "@/components/Icons/Social";
import Link from "next/link";
import { RichText, type RichTextBlock } from "prismic-reactjs";
import { memo } from "react";

type PrismicData = {
    copyright: RichTextBlock[];
    social: { name: string; path: RichTextBlock[] }[];
    body: {
        primary: { title: RichTextBlock[] };
        items: { name: RichTextBlock[]; path: RichTextBlock[] }[];
    }[];
};
const getText = (richText: RichTextBlock[]): string =>
    RichText.asText(richText);

export const DesktopFooter = memo(() => {
    const data = documentFooter.data as PrismicData;
    const links = data.body.map((b) => ({
        title: getText(b.primary.title),
        items: b.items.map((i) => ({
            name: getText(i.name),
            path: getText(i.path),
        })),
    }));
    const social = data.social.map((s) => ({
        icon: (
            <SocialIcon
                type={s.name}
                props={{ className: "text-primary size-6" }}
            />
        ),
        path: getText(s.path),
    }));

    const footer = { copyright: getText(data.copyright), links, social };

    return (
        <footer className="hidden flex-col items-center gap-16 divide-x-0 divide-y divide-solid divide-blue-grey-50 bg-white py-16 lg:flex">
            <div className="flex w-full max-w-[1056px] justify-between">
                <Link href="/" passHref>
                    <a>
                        <LogomarkWithNameBlue />
                    </a>
                </Link>
                {footer.links.map((l) => (
                    <div
                        key={l.title}
                        className="typography-sub flex flex-col gap-4"
                    >
                        <div className="font-bold text-blue-grey-900">
                            {l.title}
                        </div>
                        <div className="flex flex-col gap-3">
                            {l.items.map((i) => (
                                <Link key={i.name} href={i.path}>
                                    <a className="remove-styles-a cursor-pointer text-blue-grey-300">
                                        {i.name}
                                    </a>
                                </Link>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
            <div className="flex w-full max-w-[1056px] justify-between pt-6">
                <div className="typography-sub text-blue-grey-400">
                    {footer.copyright}
                </div>
                <div className="flex gap-2">
                    {footer.social.map((s) => (
                        <Link key={s.path} href={s.path}>
                            <a className="cursor-pointer">{s.icon}</a>
                        </Link>
                    ))}
                </div>
            </div>
        </footer>
    );
});
DesktopFooter.displayName = "Footer";
